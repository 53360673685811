import {useBaseStore} from "~/stores/base.js";
import {navigateTo} from "#app";
import {commonBeforeLogout, globalUpdateUserInfo, toLogin} from "~/utils/common";
import {config} from "~/utils/config";
import {APPID, PATH_NOT_FOUND, PATH_SERVER_ERROR} from "~/utils/constants";
import Cookies from "js-cookie";

const analyseUserLogin = () => {
  const isLogin = useBaseStore().getterIsLogin
  const cookie = Cookies.get("userInfo")
  if (!isLogin && cookie) {
    // 视为刚刚登录成功
    useBaseStore().updateIsLogin(true)
    console.log('[isLogin]', useBaseStore().getterIsLogin)
    if (process.client) window.logStatusChangeBroadcast.postMessage('change');
    globalUpdateUserInfo()
  }
}

const analyseMessage = (e) => {
  const BNTOMessage = useNuxtApp().$BNTOMessage
  switch (e.message.action) {
    case "toast":
      // todo 根据 http status 判断用哪种 message 方法
      BNTOMessage.error({message: e.message.content});
      break;
    case "logs":
      console.error('[Analyse Response]', e)
      break;
    case "redirect":
      if (process.client) window.location.href = e.message.content
      break;
    case "back":
      if (process.client) window.history.back()
      break;
  }
}

const handleSuccessResponse = async (res, options) => {
  // console.log("handleSuccessResponse", res)
  analyseUserLogin()
  const apiVersion = res.headers.get('api-version')
  if (apiVersion) useBaseStore().updateApiVersion(apiVersion)

  if (res._data.code === 0) {
    return Promise.resolve(res._data)
  } else if (res._data.code === config.BUSINESS_CODE.PRODUCT_OFF_SHELF) {
    // 商品下架
    navigateTo(PATH_NOT_FOUND)
    /** 会进入下方的 handleErrorResponse **/
    return Promise.reject(res._data)
  } else if (res._data.code === config.BUSINESS_CODE.SERVER_ERROR) {
    const error = {
      request: options,
      response: res._data
    }
    navigateTo(`${PATH_SERVER_ERROR}?error=${encodeURIComponent(JSON.stringify(error))}`)
    /** 会进入下方的 handleErrorResponse **/
    return Promise.reject(res._data)
  } else {
    /** 会进入下方的 handleErrorResponse **/
    return Promise.reject(res._data)
  }
}

const handleErrorResponse = (e) => {
  console.error("[handleErrorResponse]", e)
  if (e.code === config.BUSINESS_CODE.UNAUTHORIZED) {
    commonBeforeLogout()
    useBaseStore().updateIsLogin(false)
    console.log("[isLogin]", useBaseStore().getterIsLogin)
    toLogin()
    return Promise.reject(e)
  } else if (e.name && e.name === 'FetchError') {
    const BNTOMessage = useNuxtApp().$BNTOMessage
    BNTOMessage.error({message: "Network Error. Please try again later"});
    return Promise.reject(e)
  } else if (
    e.code === config.BUSINESS_CODE.INVALID_INVITATION_CODE ||
    e.code === config.BUSINESS_CODE.INVALID_PROMO_CODE ||
    e.code === config.BUSINESS_CODE.PROMO_CODE_USED ||
    e.code === config.BUSINESS_CODE.PROMO_CODE_EXCLUDED_PRODUCT ||
    e.code === config.BUSINESS_CODE.INSUFFICIENT_POINTS ||
    e.code === config.BUSINESS_CODE.PROMO_CODE_FIRST_SUBSCRIBE ||
    e.code === config.BUSINESS_CODE.INVALID_USER_NICKNAME
  ) {
    return Promise.reject(e)
  } else {
    analyseMessage(e)
    return Promise.reject(e)
  }
}

const getHeaders = () => {
  return {
    ...useRequestHeaders(), // 手动将客户端请求头传递到API
    "X-BNTO-APPID": APPID,
    "X-BNTO-CID": useBaseStore().getterDeviceId,
    "X-BNTO-AUTH": useBaseStore().getterXBntoAuth,
    "X-BNTO-UA": useBaseStore().getterXBntoUa,
    "X-BNTO-LOCATION": useBaseStore().getterXBntoLocation,
    "X-BNTO-SOURCE": useBaseStore().getterXBntoSource
  }
}

export const omniFetch = ({url, method, params}) => {
  const baseURL = import.meta.env.VITE_ORIGIN_BUSINESS;
  const fetchOptions = {
    baseURL,
    method,
    headers: getHeaders(),
    credentials: "include" // 请求携带cookie
  }

  switch (method.toLowerCase()) {
    case "get":
      fetchOptions.params = params;
      break;
    case "post":
      fetchOptions.body = params;
      break;
  }

  return $fetch.raw(url, fetchOptions)
    .then(res => handleSuccessResponse(res, {url, method, params}))
    .catch(e => handleErrorResponse(e.data || e))
}
