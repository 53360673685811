import { default as _404e5HLA4AHTxMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/404.vue?macro=true";
import { default as _500oyh8huRVOwMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/500.vue?macro=true";
import { default as account_45overview52YaaieCeoMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/account/account-overview.vue?macro=true";
import { default as billing_45and_45receiptsu8hTM0wNWrMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/account/billing-and-receipts.vue?macro=true";
import { default as bonus_45credits_45and_45referrals22H1dEFUGHMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/account/bonus-credits-and-referrals.vue?macro=true";
import { default as my_45profileKqh3SF4ENPMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/account/my-profile.vue?macro=true";
import { default as my_45subscriptionXsISMEYbEMMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/account/my-subscription.vue?macro=true";
import { default as rental_45history1ctiQyR5OHMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/account/rental-history.vue?macro=true";
import { default as accounthyBPy2Ii7tMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/account.vue?macro=true";
import { default as _91id_93Gnz9o9YdJyMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/brand/[id].vue?macro=true";
import { default as brandspYJdoipX4zMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/brands.vue?macro=true";
import { default as _91CATEGORY_93pzXDZZNBFyMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/category/[CATEGORY].vue?macro=true";
import { default as _91COLLECTION_93svok2F7yKqMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/collection/[COLLECTION].vue?macro=true";
import { default as email_45unsubscribedtI8iHjiUgJMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/email-unsubscribed.vue?macro=true";
import { default as homeRu8O1C1EzRMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/home.vue?macro=true";
import { default as loginKdQoJ9k6JkMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/login.vue?macro=true";
import { default as new_45arrivalsyPRGblRJTWMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/new-arrivals.vue?macro=true";
import { default as occasionsW72gtF7pQkMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/occasions.vue?macro=true";
import { default as payment_45result_45checkvdCE81kYVAMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/payment-result-check.vue?macro=true";
import { default as _91_46_46_46slug_93aQZllIoMyvMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/product/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_930sGOs3PZoKMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/register/[...slug].vue?macro=true";
import { default as reset_45passwordWzrWyUp6b3Meta } from "/home/ec2-user/www/BNTO-webapp/src/pages/reset-password.vue?macro=true";
import { default as resubscribetAllm87s0QMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/resubscribe.vue?macro=true";
import { default as resume_45subscriptionAjA8kjyfDWMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/resume-subscription.vue?macro=true";
import { default as creategYznLoZ5e0Meta } from "/home/ec2-user/www/BNTO-webapp/src/pages/review/create.vue?macro=true";
import { default as _91SEARCH_937pOpv5Br8NMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/search/[SEARCH].vue?macro=true";
import { default as subscribenvTIPBdhzzMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/subscribe.vue?macro=true";
import { default as unlockaahjNUyDSbMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/unlock.vue?macro=true";
import { default as wishlisttndhtAp3JEMeta } from "/home/ec2-user/www/BNTO-webapp/src/pages/wishlist.vue?macro=true";
import { default as homeRnoOz6vqi0Meta } from "~/pages/home.vue?macro=true";
import { default as _404CQgvmLIGFiMeta } from "~/pages/404.vue?macro=true";
import { default as component_45stubNd1pyOiJVnMeta } from "/home/ec2-user/www/BNTO-webapp/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubNd1pyOiJVn } from "/home/ec2-user/www/BNTO-webapp/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "404___en",
    path: "/404",
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "500___en",
    path: "/500",
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/500.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/account",
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-account-overview___en",
    path: "account-overview",
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/account/account-overview.vue").then(m => m.default || m)
  },
  {
    name: "account-billing-and-receipts___en",
    path: "billing-and-receipts",
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/account/billing-and-receipts.vue").then(m => m.default || m)
  },
  {
    name: "account-bonus-credits-and-referrals___en",
    path: "bonus-credits-and-referrals",
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/account/bonus-credits-and-referrals.vue").then(m => m.default || m)
  },
  {
    name: "account-my-profile___en",
    path: "my-profile",
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/account/my-profile.vue").then(m => m.default || m)
  },
  {
    name: "account-my-subscription___en",
    path: "my-subscription",
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/account/my-subscription.vue").then(m => m.default || m)
  },
  {
    name: "account-rental-history___en",
    path: "rental-history",
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/account/rental-history.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "brand-id___en",
    path: "/brand/:id()",
    meta: _91id_93Gnz9o9YdJyMeta || {},
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/brand/[id].vue").then(m => m.default || m)
  },
  {
    name: "brands___en",
    path: "/brands",
    meta: brandspYJdoipX4zMeta || {},
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/brands.vue").then(m => m.default || m)
  },
  {
    name: "category-CATEGORY___en",
    path: "/category/:CATEGORY()",
    meta: _91CATEGORY_93pzXDZZNBFyMeta || {},
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/category/[CATEGORY].vue").then(m => m.default || m)
  },
  {
    name: "collection-COLLECTION___en",
    path: "/collection/:COLLECTION()",
    meta: _91COLLECTION_93svok2F7yKqMeta || {},
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/collection/[COLLECTION].vue").then(m => m.default || m)
  },
  {
    name: "email-unsubscribed___en",
    path: "/email-unsubscribed",
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/email-unsubscribed.vue").then(m => m.default || m)
  },
  {
    name: "home___en",
    path: "/home",
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/login",
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "new-arrivals___en",
    path: "/new-arrivals",
    meta: new_45arrivalsyPRGblRJTWMeta || {},
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/new-arrivals.vue").then(m => m.default || m)
  },
  {
    name: "occasions___en",
    path: "/occasions",
    meta: occasionsW72gtF7pQkMeta || {},
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/occasions.vue").then(m => m.default || m)
  },
  {
    name: "payment-result-check___en",
    path: "/payment-result-check",
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/payment-result-check.vue").then(m => m.default || m)
  },
  {
    name: "product-slug___en",
    path: "/product/:slug(.*)*",
    meta: _91_46_46_46slug_93aQZllIoMyvMeta || {},
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "register-slug___en",
    path: "/register/:slug(.*)*",
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/register/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "reset-password___en",
    path: "/reset-password",
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "resubscribe___en",
    path: "/resubscribe",
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/resubscribe.vue").then(m => m.default || m)
  },
  {
    name: "resume-subscription___en",
    path: "/resume-subscription",
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/resume-subscription.vue").then(m => m.default || m)
  },
  {
    name: "review-create___en",
    path: "/review/create",
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/review/create.vue").then(m => m.default || m)
  },
  {
    name: "search-SEARCH___en",
    path: "/search/:SEARCH()",
    meta: _91SEARCH_937pOpv5Br8NMeta || {},
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/search/[SEARCH].vue").then(m => m.default || m)
  },
  {
    name: "subscribe___en",
    path: "/subscribe",
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/subscribe.vue").then(m => m.default || m)
  },
  {
    name: "unlock___en",
    path: "/unlock",
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/unlock.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___en",
    path: "/wishlist",
    meta: wishlisttndhtAp3JEMeta || {},
    component: () => import("/home/ec2-user/www/BNTO-webapp/src/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "home___en",
    path: "/",
    component: () => import("~/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "404___en",
    path: "/:pathMatch(.*)*",
    component: () => import("~/pages/404.vue").then(m => m.default || m)
  },
  {
    name: component_45stubNd1pyOiJVnMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubNd1pyOiJVn
  },
  {
    name: component_45stubNd1pyOiJVnMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubNd1pyOiJVn
  },
  {
    name: component_45stubNd1pyOiJVnMeta?.name,
    path: "/en-US-sitemap.xml",
    component: component_45stubNd1pyOiJVn
  }
]